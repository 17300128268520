// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "25.2.0",
  "buildNumber": "20377",
  "gitSha": "e8f87c74d55bfcdb3ccebce3e779ef12ebb904a7",
  "fullVersion": "25.2.0-20377",
  "formattedVersion": "25.2.0 (20377-e8f87c74d55bfcdb3ccebce3e779ef12ebb904a7)"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/25.2.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/25.2.0/${stripLeadingPrefix(suffix, '#')}`
}
    
